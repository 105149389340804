export enum POIsClasification {
  'Category' = 'categories',
  'Brand' = 'brands',
  'Locations' = 'locations',
  'CustomPOIs' = 'custom',
  'Tags' = 'tags',
}

export enum POIsFilterMethod {
  'Radius' = 'RADIUS',
  'Walking' = 'WALKING',
  'Driving' = 'DRIVING',
}

export enum POIsFilterOperation {
  Union = 'union',
  Intersection = 'intersection',
}

export interface Category {
  top_category: string
  sub_category: string
  occurrences: number
}

interface CustomPOIsGroupBasic {
  id: string
  name: string
  shared: boolean
  user_email: string
  modified?: string | Date
  description?: string
  countries?: number[]
}

export interface CustomPOIsGroup extends CustomPOIsGroupBasic, Category {}

export interface Location {
  name: string
  normalized_name: string
  occurrences: number
  country_id: number
}

export interface Brand {
  brands: string
  occurrences: number
}

export interface Tag {
  tags: string
  occurrences: number
}

export interface Geoid {
  geoid: string
}

export interface TypesOfPOIs {
  categories: string[]
  brands: string[]
  tags: string[]
  locations: string[]
  custom_POIs: string[]
}

export interface FilterByPoisParamsRequest extends TypesOfPOIs {
  operation: POIsFilterOperation
  max_distance: number
  min_distance: number
  distance: number
  method: POIsFilterMethod
  max_assets_per_poi: number
}

export interface CustomPOI {
  name: string
  geom: string
  address?: string
}

export interface CustomPOIConvertedByCarto {
  name: string
  geom: {
    coordinates: number[]
  }
  address?: string
}

export interface CustomPOIsDataset {
  pois: CustomPOI[]
}

export interface CustomPOIsGroupRequest extends CustomPOIsGroupBasic {
  customPOIs: CustomPOI[]
  description: string
  sub_categories: string[]
  action?: 'create' | 'update'
}

export interface CustomPOIsDatasetParamsRequest {
  id: string
  user_email: string
}

export interface CustomPOIAddress {
  name: string
  country: string
  address: string
}
export interface CustomPOIsAddressGroupRequest extends CustomPOIsGroupBasic {
  description: string
  sub_categories: string[]
  action: string
  customPOIsAddress: CustomPOIAddress[]
}

export enum CustomPOIsImportMode {
  'Coordinates' = 'COORDINATES',
  'Geocode' = 'GEOCODE',
}
