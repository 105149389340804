import { Auth, Plan } from '@workspaces/types'
import { UserPermissions } from '../permissions.manager.type'
import ResolverBasic from './resolver-basic'
/**
 * 📢 3 different types of roles:
 *    - Admin
 *    - Power User
 *    - User
 */
class PermissionResolverEU extends ResolverBasic implements UserPermissions {
  canUploadCustomPOIs(): boolean {
    return true
  }

  canEditAnyCustomPOIGroup(): boolean {
    const role = this.getRole()
    return role === Auth.Role.Admin
  }

  permitComments(): boolean {
    return false
  }

  canSavePlan(
    planOwner: string,
    planId: string | undefined,
    isPlanPublic: boolean,
  ): boolean {
    if (!planId) {
      return true
    }
    if (isPlanPublic && this.getRole() === Auth.Role.Admin) {
      return true
    }

    if (this.getName() === planOwner) {
      return true
    }

    return false
  }

  canSavePlanAsPublic(): boolean {
    return true
  }

  canDeletePlan(plan: Plan.Plan): boolean {
    const planOwner = plan.created_by
    const isPlanPublic = plan.is_public
    if (isPlanPublic && this.getRole() === Auth.Role.Admin) {
      return true
    }

    return this.getName() === planOwner
  }

  canDeleteCustomGeoboundary(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  canDeleteCustomPOIGroup(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPrice(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canBookPlanPackage(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canConfirmBookingPlanPackageByAgency(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canFinalizeConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canCancelPlanPackageByAdmin(plan: Plan.Plan): boolean {
    return false
  }

  canShowWidgetUploadAssets(): boolean {
    return true
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canEditPlanPackageDates(plan: Plan.Plan): boolean {
    return false
  }
}

export default PermissionResolverEU
